/*!
 * Bootstrap Grid Flexbox v1.1.2 (https://github.com/ngengs/bootstrap-grid-flexbox)
 * Copyright 2016 Rizky Kharisma @ngengs (http://ngengs.com).
 * Licensed under MIT (https://github.com/ngengs/bootstrap-grid-flexbox/blob/master/LICENSE)
 */
.row-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  margin-right: -15px;
  margin-left: -15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;

  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.row-flex.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.col-flex-xs-1, .col-flex-sm-1, .col-flex-md-1, .col-flex-lg-1, .col-flex-xs-2, .col-flex-sm-2, .col-flex-md-2, .col-flex-lg-2, .col-flex-xs-3, .col-flex-sm-3, .col-flex-md-3, .col-flex-lg-3, .col-flex-xs-4, .col-flex-sm-4, .col-flex-md-4, .col-flex-lg-4, .col-flex-xs-5, .col-flex-sm-5, .col-flex-md-5, .col-flex-lg-5, .col-flex-xs-6, .col-flex-sm-6, .col-flex-md-6, .col-flex-lg-6, .col-flex-xs-7, .col-flex-sm-7, .col-flex-md-7, .col-flex-lg-7, .col-flex-xs-8, .col-flex-sm-8, .col-flex-md-8, .col-flex-lg-8, .col-flex-xs-9, .col-flex-sm-9, .col-flex-md-9, .col-flex-lg-9, .col-flex-xs-10, .col-flex-sm-10, .col-flex-md-10, .col-flex-lg-10, .col-flex-xs-11, .col-flex-sm-11, .col-flex-md-11, .col-flex-lg-11, .col-flex-xs-12, .col-flex-sm-12, .col-flex-md-12, .col-flex-lg-12 {
  width: 100%;
  max-width: 100%;
  min-height: 1px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;

  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
}

.start-xs {
  -ms-flex-pack: start;

  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.start-xs.text-start {
  text-align: start;
}

.center-xs {
  -ms-flex-pack: center;

  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.center-xs.text-center {
  text-align: center;
}

.end-xs {
  -ms-flex-pack: end;

  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.end-xs.text-end {
  text-align: end;
}

.top-xs {
  -ms-flex-align: start;

  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.middle-xs {
  -ms-flex-align: center;

  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}

.bottom-xs {
  -ms-flex-align: end;

  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.col-flex-xs-1 {
  width: 8.333333%;
  max-width: 8.333333%;

  -webkit-flex-basis: 8.333333%;
  -ms-flex-preferred-size: 8.333333%;
          flex-basis: 8.333333%;
}

.col-flex-xs-2 {
  width: 16.666667%;
  max-width: 16.666667%;

  -webkit-flex-basis: 16.666667%;
  -ms-flex-preferred-size: 16.666667%;
          flex-basis: 16.666667%;
}

.col-flex-xs-3 {
  width: 25%;
  max-width: 25%;

  -webkit-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
          flex-basis: 25%;
}

.col-flex-xs-4 {
  width: 33.333333%;
  max-width: 33.333333%;

  -webkit-flex-basis: 33.333333%;
  -ms-flex-preferred-size: 33.333333%;
          flex-basis: 33.333333%;
}

.col-flex-xs-5 {
  width: 41.666667%;
  max-width: 41.666667%;

  -webkit-flex-basis: 41.666667%;
  -ms-flex-preferred-size: 41.666667%;
          flex-basis: 41.666667%;
}

.col-flex-xs-6 {
  width: 50%;
  max-width: 50%;

  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
}

.col-flex-xs-7 {
  width: 58.333333%;
  max-width: 58.333333%;

  -webkit-flex-basis: 58.333333%;
  -ms-flex-preferred-size: 58.333333%;
          flex-basis: 58.333333%;
}

.col-flex-xs-8 {
  width: 66.666667%;
  max-width: 66.666667%;

  -webkit-flex-basis: 66.666667%;
  -ms-flex-preferred-size: 66.666667%;
          flex-basis: 66.666667%;
}

.col-flex-xs-9 {
  width: 75%;
  max-width: 75%;

  -webkit-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
          flex-basis: 75%;
}

.col-flex-xs-10 {
  width: 83.333333%;
  max-width: 83.333333%;

  -webkit-flex-basis: 83.333333%;
  -ms-flex-preferred-size: 83.333333%;
          flex-basis: 83.333333%;
}

.col-flex-xs-11 {
  width: 91.666667%;
  max-width: 91.666667%;

  -webkit-flex-basis: 91.666667%;
  -ms-flex-preferred-size: 91.666667%;
          flex-basis: 91.666667%;
}

.col-flex-xs-12 {
  width: 100%;
  max-width: 100%;

  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
}

.col-flex-xs-pull-0 {
  right: auto;
}

.col-flex-xs-pull-1 {
  right: 8.333333%;
}

.col-flex-xs-pull-2 {
  right: 16.666667%;
}

.col-flex-xs-pull-3 {
  right: 25%;
}

.col-flex-xs-pull-4 {
  right: 33.333333%;
}

.col-flex-xs-pull-5 {
  right: 41.666667%;
}

.col-flex-xs-pull-6 {
  right: 50%;
}

.col-flex-xs-pull-7 {
  right: 58.333333%;
}

.col-flex-xs-pull-8 {
  right: 66.666667%;
}

.col-flex-xs-pull-9 {
  right: 75%;
}

.col-flex-xs-pull-10 {
  right: 83.333333%;
}

.col-flex-xs-pull-11 {
  right: 91.666667%;
}

.col-flex-xs-pull-12 {
  right: 100%;
}

.col-flex-xs-push-0 {
  left: auto;
}

.col-flex-xs-push-1 {
  left: 8.333333%;
}

.col-flex-xs-push-2 {
  left: 16.666667%;
}

.col-flex-xs-push-3 {
  left: 25%;
}

.col-flex-xs-push-4 {
  left: 33.333333%;
}

.col-flex-xs-push-5 {
  left: 41.666667%;
}

.col-flex-xs-push-6 {
  left: 50%;
}

.col-flex-xs-push-7 {
  left: 58.333333%;
}

.col-flex-xs-push-8 {
  left: 66.666667%;
}

.col-flex-xs-push-9 {
  left: 75%;
}

.col-flex-xs-push-10 {
  left: 83.333333%;
}

.col-flex-xs-push-11 {
  left: 91.666667%;
}

.col-flex-xs-push-12 {
  left: 100%;
}

.col-flex-xs-offset-0 {
  margin-left: 0;
}

.col-flex-xs-offset-1 {
  margin-left: 8.333333%;
}

.col-flex-xs-offset-2 {
  margin-left: 16.666667%;
}

.col-flex-xs-offset-3 {
  margin-left: 25%;
}

.col-flex-xs-offset-4 {
  margin-left: 33.333333%;
}

.col-flex-xs-offset-5 {
  margin-left: 41.666667%;
}

.col-flex-xs-offset-6 {
  margin-left: 50%;
}

.col-flex-xs-offset-7 {
  margin-left: 58.333333%;
}

.col-flex-xs-offset-8 {
  margin-left: 66.666667%;
}

.col-flex-xs-offset-9 {
  margin-left: 75%;
}

.col-flex-xs-offset-10 {
  margin-left: 83.333333%;
}

.col-flex-xs-offset-11 {
  margin-left: 91.666667%;
}

.col-flex-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .start-sm {
    -ms-flex-pack: start;

    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .start-sm.text-start {
    text-align: start;
  }
  .center-sm {
    -ms-flex-pack: center;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .center-sm.text-center {
    text-align: center;
  }
  .end-sm {
    -ms-flex-pack: end;

    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
  .end-sm.text-end {
    text-align: end;
  }
  .top-sm {
    -ms-flex-align: start;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
  .middle-sm {
    -ms-flex-align: center;

    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .bottom-sm {
    -ms-flex-align: end;

    -webkit-box-align: end;
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }
  .col-flex-sm-1 {
    width: 8.333333%;
    max-width: 8.333333%;

    -webkit-flex-basis: 8.333333%;
    -ms-flex-preferred-size: 8.333333%;
            flex-basis: 8.333333%;
  }
  .col-flex-sm-2 {
    width: 16.666667%;
    max-width: 16.666667%;

    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
            flex-basis: 16.666667%;
  }
  .col-flex-sm-3 {
    width: 25%;
    max-width: 25%;

    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
            flex-basis: 25%;
  }
  .col-flex-sm-4 {
    width: 33.333333%;
    max-width: 33.333333%;

    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
            flex-basis: 33.333333%;
  }
  .col-flex-sm-5 {
    width: 41.666667%;
    max-width: 41.666667%;

    -webkit-flex-basis: 41.666667%;
    -ms-flex-preferred-size: 41.666667%;
            flex-basis: 41.666667%;
  }
  .col-flex-sm-6 {
    width: 50%;
    max-width: 50%;

    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
            flex-basis: 50%;
  }
  .col-flex-sm-7 {
    width: 58.333333%;
    max-width: 58.333333%;

    -webkit-flex-basis: 58.333333%;
    -ms-flex-preferred-size: 58.333333%;
            flex-basis: 58.333333%;
  }
  .col-flex-sm-8 {
    width: 66.666667%;
    max-width: 66.666667%;

    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
            flex-basis: 66.666667%;
  }
  .col-flex-sm-9 {
    width: 75%;
    max-width: 75%;

    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
            flex-basis: 75%;
  }
  .col-flex-sm-10 {
    width: 83.333333%;
    max-width: 83.333333%;

    -webkit-flex-basis: 83.333333%;
    -ms-flex-preferred-size: 83.333333%;
            flex-basis: 83.333333%;
  }
  .col-flex-sm-11 {
    width: 91.666667%;
    max-width: 91.666667%;

    -webkit-flex-basis: 91.666667%;
    -ms-flex-preferred-size: 91.666667%;
            flex-basis: 91.666667%;
  }
  .col-flex-sm-12 {
    width: 100%;
    max-width: 100%;

    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
  }
  .col-flex-sm-pull-0 {
    right: auto;
  }
  .col-flex-sm-pull-1 {
    right: 8.333333%;
  }
  .col-flex-sm-pull-2 {
    right: 16.666667%;
  }
  .col-flex-sm-pull-3 {
    right: 25%;
  }
  .col-flex-sm-pull-4 {
    right: 33.333333%;
  }
  .col-flex-sm-pull-5 {
    right: 41.666667%;
  }
  .col-flex-sm-pull-6 {
    right: 50%;
  }
  .col-flex-sm-pull-7 {
    right: 58.333333%;
  }
  .col-flex-sm-pull-8 {
    right: 66.666667%;
  }
  .col-flex-sm-pull-9 {
    right: 75%;
  }
  .col-flex-sm-pull-10 {
    right: 83.333333%;
  }
  .col-flex-sm-pull-11 {
    right: 91.666667%;
  }
  .col-flex-sm-pull-12 {
    right: 100%;
  }
  .col-flex-sm-push-0 {
    left: auto;
  }
  .col-flex-sm-push-1 {
    left: 8.333333%;
  }
  .col-flex-sm-push-2 {
    left: 16.666667%;
  }
  .col-flex-sm-push-3 {
    left: 25%;
  }
  .col-flex-sm-push-4 {
    left: 33.333333%;
  }
  .col-flex-sm-push-5 {
    left: 41.666667%;
  }
  .col-flex-sm-push-6 {
    left: 50%;
  }
  .col-flex-sm-push-7 {
    left: 58.333333%;
  }
  .col-flex-sm-push-8 {
    left: 66.666667%;
  }
  .col-flex-sm-push-9 {
    left: 75%;
  }
  .col-flex-sm-push-10 {
    left: 83.333333%;
  }
  .col-flex-sm-push-11 {
    left: 91.666667%;
  }
  .col-flex-sm-push-12 {
    left: 100%;
  }
  .col-flex-sm-offset-0 {
    margin-left: 0;
  }
  .col-flex-sm-offset-1 {
    margin-left: 8.333333%;
  }
  .col-flex-sm-offset-2 {
    margin-left: 16.666667%;
  }
  .col-flex-sm-offset-3 {
    margin-left: 25%;
  }
  .col-flex-sm-offset-4 {
    margin-left: 33.333333%;
  }
  .col-flex-sm-offset-5 {
    margin-left: 41.666667%;
  }
  .col-flex-sm-offset-6 {
    margin-left: 50%;
  }
  .col-flex-sm-offset-7 {
    margin-left: 58.333333%;
  }
  .col-flex-sm-offset-8 {
    margin-left: 66.666667%;
  }
  .col-flex-sm-offset-9 {
    margin-left: 75%;
  }
  .col-flex-sm-offset-10 {
    margin-left: 83.333333%;
  }
  .col-flex-sm-offset-11 {
    margin-left: 91.666667%;
  }
  .col-flex-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .start-md {
    -ms-flex-pack: start;

    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .start-md.text-start {
    text-align: start;
  }
  .center-md {
    -ms-flex-pack: center;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .center-md.text-center {
    text-align: center;
  }
  .end-md {
    -ms-flex-pack: end;

    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
  .end-md.text-end {
    text-align: end;
  }
  .top-md {
    -ms-flex-align: start;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
  .middle-md {
    -ms-flex-align: center;

    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .bottom-md {
    -ms-flex-align: end;

    -webkit-box-align: end;
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }
  .col-flex-md-1 {
    width: 8.333333%;
    max-width: 8.333333%;

    -webkit-flex-basis: 8.333333%;
    -ms-flex-preferred-size: 8.333333%;
            flex-basis: 8.333333%;
  }
  .col-flex-md-2 {
    width: 16.666667%;
    max-width: 16.666667%;

    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
            flex-basis: 16.666667%;
  }
  .col-flex-md-3 {
    width: 25%;
    max-width: 25%;

    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
            flex-basis: 25%;
  }
  .col-flex-md-4 {
    width: 33.333333%;
    max-width: 33.333333%;

    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
            flex-basis: 33.333333%;
  }
  .col-flex-md-5 {
    width: 41.666667%;
    max-width: 41.666667%;

    -webkit-flex-basis: 41.666667%;
    -ms-flex-preferred-size: 41.666667%;
            flex-basis: 41.666667%;
  }
  .col-flex-md-6 {
    width: 50%;
    max-width: 50%;

    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
            flex-basis: 50%;
  }
  .col-flex-md-7 {
    width: 58.333333%;
    max-width: 58.333333%;

    -webkit-flex-basis: 58.333333%;
    -ms-flex-preferred-size: 58.333333%;
            flex-basis: 58.333333%;
  }
  .col-flex-md-8 {
    width: 66.666667%;
    max-width: 66.666667%;

    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
            flex-basis: 66.666667%;
  }
  .col-flex-md-9 {
    width: 75%;
    max-width: 75%;

    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
            flex-basis: 75%;
  }
  .col-flex-md-10 {
    width: 83.333333%;
    max-width: 83.333333%;

    -webkit-flex-basis: 83.333333%;
    -ms-flex-preferred-size: 83.333333%;
            flex-basis: 83.333333%;
  }
  .col-flex-md-11 {
    width: 91.666667%;
    max-width: 91.666667%;

    -webkit-flex-basis: 91.666667%;
    -ms-flex-preferred-size: 91.666667%;
            flex-basis: 91.666667%;
  }
  .col-flex-md-12 {
    width: 100%;
    max-width: 100%;

    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
  }
  .col-flex-md-pull-0 {
    right: auto;
  }
  .col-flex-md-pull-1 {
    right: 8.333333%;
  }
  .col-flex-md-pull-2 {
    right: 16.666667%;
  }
  .col-flex-md-pull-3 {
    right: 25%;
  }
  .col-flex-md-pull-4 {
    right: 33.333333%;
  }
  .col-flex-md-pull-5 {
    right: 41.666667%;
  }
  .col-flex-md-pull-6 {
    right: 50%;
  }
  .col-flex-md-pull-7 {
    right: 58.333333%;
  }
  .col-flex-md-pull-8 {
    right: 66.666667%;
  }
  .col-flex-md-pull-9 {
    right: 75%;
  }
  .col-flex-md-pull-10 {
    right: 83.333333%;
  }
  .col-flex-md-pull-11 {
    right: 91.666667%;
  }
  .col-flex-md-pull-12 {
    right: 100%;
  }
  .col-flex-md-push-0 {
    left: auto;
  }
  .col-flex-md-push-1 {
    left: 8.333333%;
  }
  .col-flex-md-push-2 {
    left: 16.666667%;
  }
  .col-flex-md-push-3 {
    left: 25%;
  }
  .col-flex-md-push-4 {
    left: 33.333333%;
  }
  .col-flex-md-push-5 {
    left: 41.666667%;
  }
  .col-flex-md-push-6 {
    left: 50%;
  }
  .col-flex-md-push-7 {
    left: 58.333333%;
  }
  .col-flex-md-push-8 {
    left: 66.666667%;
  }
  .col-flex-md-push-9 {
    left: 75%;
  }
  .col-flex-md-push-10 {
    left: 83.333333%;
  }
  .col-flex-md-push-11 {
    left: 91.666667%;
  }
  .col-flex-md-push-12 {
    left: 100%;
  }
  .col-flex-md-offset-0 {
    margin-left: 0;
  }
  .col-flex-md-offset-1 {
    margin-left: 8.333333%;
  }
  .col-flex-md-offset-2 {
    margin-left: 16.666667%;
  }
  .col-flex-md-offset-3 {
    margin-left: 25%;
  }
  .col-flex-md-offset-4 {
    margin-left: 33.333333%;
  }
  .col-flex-md-offset-5 {
    margin-left: 41.666667%;
  }
  .col-flex-md-offset-6 {
    margin-left: 50%;
  }
  .col-flex-md-offset-7 {
    margin-left: 58.333333%;
  }
  .col-flex-md-offset-8 {
    margin-left: 66.666667%;
  }
  .col-flex-md-offset-9 {
    margin-left: 75%;
  }
  .col-flex-md-offset-10 {
    margin-left: 83.333333%;
  }
  .col-flex-md-offset-11 {
    margin-left: 91.666667%;
  }
  .col-flex-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .start-lg {
    -ms-flex-pack: start;

    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .start-lg.text-start {
    text-align: start;
  }
  .center-lg {
    -ms-flex-pack: center;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .center-lg.text-center {
    text-align: center;
  }
  .end-lg {
    -ms-flex-pack: end;

    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
  .end-lg.text-end {
    text-align: end;
  }
  .top-lg {
    -ms-flex-align: start;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
  .middle-lg {
    -ms-flex-align: center;

    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .bottom-lg {
    -ms-flex-align: end;

    -webkit-box-align: end;
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }
  .col-flex-lg-1 {
    width: 8.333333%;
    max-width: 8.333333%;

    -webkit-flex-basis: 8.333333%;
    -ms-flex-preferred-size: 8.333333%;
            flex-basis: 8.333333%;
  }
  .col-flex-lg-2 {
    width: 16.666667%;
    max-width: 16.666667%;

    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
            flex-basis: 16.666667%;
  }
  .col-flex-lg-3 {
    width: 25%;
    max-width: 25%;

    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
            flex-basis: 25%;
  }
  .col-flex-lg-4 {
    width: 33.333333%;
    max-width: 33.333333%;

    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
            flex-basis: 33.333333%;
  }
  .col-flex-lg-5 {
    width: 41.666667%;
    max-width: 41.666667%;

    -webkit-flex-basis: 41.666667%;
    -ms-flex-preferred-size: 41.666667%;
            flex-basis: 41.666667%;
  }
  .col-flex-lg-6 {
    width: 50%;
    max-width: 50%;

    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
            flex-basis: 50%;
  }
  .col-flex-lg-7 {
    width: 58.333333%;
    max-width: 58.333333%;

    -webkit-flex-basis: 58.333333%;
    -ms-flex-preferred-size: 58.333333%;
            flex-basis: 58.333333%;
  }
  .col-flex-lg-8 {
    width: 66.666667%;
    max-width: 66.666667%;

    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
            flex-basis: 66.666667%;
  }
  .col-flex-lg-9 {
    width: 75%;
    max-width: 75%;

    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
            flex-basis: 75%;
  }
  .col-flex-lg-10 {
    width: 83.333333%;
    max-width: 83.333333%;

    -webkit-flex-basis: 83.333333%;
    -ms-flex-preferred-size: 83.333333%;
            flex-basis: 83.333333%;
  }
  .col-flex-lg-11 {
    width: 91.666667%;
    max-width: 91.666667%;

    -webkit-flex-basis: 91.666667%;
    -ms-flex-preferred-size: 91.666667%;
            flex-basis: 91.666667%;
  }
  .col-flex-lg-12 {
    width: 100%;
    max-width: 100%;

    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
  }
  .col-flex-lg-pull-0 {
    right: auto;
  }
  .col-flex-lg-pull-1 {
    right: 8.333333%;
  }
  .col-flex-lg-pull-2 {
    right: 16.666667%;
  }
  .col-flex-lg-pull-3 {
    right: 25%;
  }
  .col-flex-lg-pull-4 {
    right: 33.333333%;
  }
  .col-flex-lg-pull-5 {
    right: 41.666667%;
  }
  .col-flex-lg-pull-6 {
    right: 50%;
  }
  .col-flex-lg-pull-7 {
    right: 58.333333%;
  }
  .col-flex-lg-pull-8 {
    right: 66.666667%;
  }
  .col-flex-lg-pull-9 {
    right: 75%;
  }
  .col-flex-lg-pull-10 {
    right: 83.333333%;
  }
  .col-flex-lg-pull-11 {
    right: 91.666667%;
  }
  .col-flex-lg-pull-12 {
    right: 100%;
  }
  .col-flex-lg-push-0 {
    left: auto;
  }
  .col-flex-lg-push-1 {
    left: 8.333333%;
  }
  .col-flex-lg-push-2 {
    left: 16.666667%;
  }
  .col-flex-lg-push-3 {
    left: 25%;
  }
  .col-flex-lg-push-4 {
    left: 33.333333%;
  }
  .col-flex-lg-push-5 {
    left: 41.666667%;
  }
  .col-flex-lg-push-6 {
    left: 50%;
  }
  .col-flex-lg-push-7 {
    left: 58.333333%;
  }
  .col-flex-lg-push-8 {
    left: 66.666667%;
  }
  .col-flex-lg-push-9 {
    left: 75%;
  }
  .col-flex-lg-push-10 {
    left: 83.333333%;
  }
  .col-flex-lg-push-11 {
    left: 91.666667%;
  }
  .col-flex-lg-push-12 {
    left: 100%;
  }
  .col-flex-lg-offset-0 {
    margin-left: 0;
  }
  .col-flex-lg-offset-1 {
    margin-left: 8.333333%;
  }
  .col-flex-lg-offset-2 {
    margin-left: 16.666667%;
  }
  .col-flex-lg-offset-3 {
    margin-left: 25%;
  }
  .col-flex-lg-offset-4 {
    margin-left: 33.333333%;
  }
  .col-flex-lg-offset-5 {
    margin-left: 41.666667%;
  }
  .col-flex-lg-offset-6 {
    margin-left: 50%;
  }
  .col-flex-lg-offset-7 {
    margin-left: 58.333333%;
  }
  .col-flex-lg-offset-8 {
    margin-left: 66.666667%;
  }
  .col-flex-lg-offset-9 {
    margin-left: 75%;
  }
  .col-flex-lg-offset-10 {
    margin-left: 83.333333%;
  }
  .col-flex-lg-offset-11 {
    margin-left: 91.666667%;
  }
  .col-flex-lg-offset-12 {
    margin-left: 100%;
  }
}
/*# sourceMappingURL=bootstrap-grid-flexbox.css.map */
